import { t } from 'services/i18n'
import { SEARCH_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/opportunity_finder/search'
import { JSPRO_BUCKET } from 'constants/s3'

export const COUNTRIES_LABELS = {
  us: t('generic:Countries.us', 'United States'),
  ca: t('generic:Countries.ca', 'Canada'),
  mx: t('generic:Countries.mx', 'Mexico'),
  br: t('generic:Countries.br', 'Brazil'),
  de: t('generic:Countries.de', 'Germany'),
  es: t('generic:Countries.es', 'Spain'),
  fr: t('generic:Countries.fr', 'France'),
  be: t('generic:Countries.be', 'Belgium'),
  in: t('generic:Countries.in', 'India'),
  it: t('generic:Countries.it', 'Italy'),
  uk: t('generic:Countries.uk', 'United Kingdom'),
  nl: t('generic:Countries.nl', 'Netherlands'),
  tr: t('generic:Countries.tr', 'Turkey'),
  ae: t('generic:Countries.ae', 'United Arab Emirates'),
  sa: t('generic:Countries.sa', 'Saudi Arabia'),
  sg: t('generic:Countries.sg', 'Singapore'),
  au: t('generic:Countries.au', 'Australia'),
  jp: t('generic:Countries.jp', 'Japan'),
  cn: t('generic:Countries.cn', 'China'),
  eg: t('generic:Countries.eg', 'Egypt'),
  se: t('generic:Countries.se', 'Sweden'),
  pl: t('generic:Countries.pl', 'Poland')
}

export const COUNTRIES = {
  us: {
    label: 'United States',
    image: `${JSPRO_BUCKET}/images/flags/US.svg`
  },
  ca: {
    label: 'Canada',
    image: `${JSPRO_BUCKET}/images/flags/Canada.svg`
  },
  mx: {
    label: 'Mexico',
    image: `${JSPRO_BUCKET}/images/flags/Mexico.svg`
  },
  br: {
    label: 'Brazil',
    image: `${JSPRO_BUCKET}/images/flags/BR.svg`
  },
  de: {
    label: 'Germany',
    image: `${JSPRO_BUCKET}/images/flags/Germany.svg`
  },
  es: {
    label: 'Spain',
    image: `${JSPRO_BUCKET}/images/flags/Spain.svg`
  },
  fr: {
    label: 'France',
    image: `${JSPRO_BUCKET}/images/flags/France.svg`
  },
  be: {
    label: 'Belgium',
    image: `${JSPRO_BUCKET}/images/flags/Belgium.svg`
  },
  in: {
    label: 'India',
    image: `${JSPRO_BUCKET}/images/flags/India.svg`
  },
  it: {
    label: 'Italy',
    image: `${JSPRO_BUCKET}/images/flags/Italy.svg`
  },
  uk: {
    label: 'United Kingdom',
    image: `${JSPRO_BUCKET}/images/flags/United_Kingdom.svg`
  },
  nl: {
    label: 'Netherlands',
    image: `${JSPRO_BUCKET}/images/flags/NL.svg`
  },
  tr: {
    label: 'Turkey',
    image: `${JSPRO_BUCKET}/images/flags/TR.svg`
  },
  ae: {
    label: 'United Arab Emirates',
    image: `${JSPRO_BUCKET}/images/flags/AE.svg`
  },
  sa: {
    label: 'Saudi Arabia',
    image: `${JSPRO_BUCKET}/images/flags/SA.svg`
  },
  eg: {
    label: 'Egypt',
    image: `${JSPRO_BUCKET}/images/flags/EG.svg`
  },
  jp: {
    label: 'Japan',
    image: `${JSPRO_BUCKET}/images/flags/JP.svg`
  },
  pl: {
    label: 'Poland',
    image: `${JSPRO_BUCKET}/images/flags/PL.svg`
  },
  au: {
    label: 'Australia',
    image: `${JSPRO_BUCKET}/images/flags/AU.svg`
  },
  sg: {
    label: 'Singapore',
    image: `${JSPRO_BUCKET}/images/flags/SG.svg`
  }
}

export const MARKETPLACES = {
  us: {
    code: 'us',
    name: COUNTRIES_LABELS.us,
    value: 'US',
    languageCode: 'en-US',
    isEnabled: true,
    topDomain: 'com'
  },
  ca: {
    code: 'ca',
    name: COUNTRIES_LABELS.ca,
    value: 'CA',
    languageCode: 'en-CA',
    isEnabled: true,
    topDomain: 'ca'
  },
  mx: {
    code: 'mx',
    name: COUNTRIES_LABELS.mx,
    value: 'MX',
    languageCode: 'es-MX',
    isEnabled: true,
    topDomain: 'com.mx'
  },
  br: {
    code: 'br',
    name: COUNTRIES_LABELS.br,
    value: 'BR',
    languageCode: 'br-PT',
    isEnabled: false,
    topDomain: 'com.br'
  },
  de: {
    code: 'de',
    name: COUNTRIES_LABELS.de,
    value: 'DE',
    languageCode: 'de-DE',
    isEnabled: true,
    topDomain: 'de'
  },
  es: {
    code: 'es',
    name: COUNTRIES_LABELS.es,
    value: 'ES',
    languageCode: 'es-ES',
    isEnabled: true,
    topDomain: 'es'
  },
  fr: {
    code: 'fr',
    name: COUNTRIES_LABELS.fr,
    value: 'FR',
    languageCode: 'fr-FR',
    isEnabled: true,
    topDomain: 'fr'
  },
  be: {
    code: 'be',
    name: COUNTRIES_LABELS.be,
    value: 'BE',
    languageCode: 'fr-BE',
    isEnabled: false,
    topDomain: 'com.be'
  },
  in: {
    code: 'in',
    name: COUNTRIES_LABELS.in,
    value: 'IN',
    languageCode: 'hi-IN',
    isEnabled: true,
    topDomain: 'in'
  },
  it: {
    code: 'it',
    name: COUNTRIES_LABELS.it,
    value: 'IT',
    languageCode: 'it-IT',
    isEnabled: true,
    topDomain: 'it'
  },
  uk: {
    code: 'uk',
    name: COUNTRIES_LABELS.uk,
    value: 'UK',
    languageCode: 'en-GB',
    isEnabled: true,
    topDomain: 'co.uk'
  },
  nl: {
    code: 'nl',
    name: COUNTRIES_LABELS.nl,
    value: 'NL',
    languageCode: 'dut-NL',
    isEnabled: false,
    topDomain: 'nl'
  },
  tr: {
    code: 'tr',
    name: COUNTRIES_LABELS.tr,
    value: 'TR',
    languageCode: 'tr-TR',
    isEnabled: false,
    topDomain: 'com.tr'
  },
  ae: {
    code: 'ae',
    name: COUNTRIES_LABELS.ae,
    value: 'AE',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'ae'
  },
  sa: {
    code: 'sa',
    name: COUNTRIES_LABELS.sa,
    value: 'SA',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'sa'
  },
  eg: {
    code: 'eg',
    name: COUNTRIES_LABELS.eg,
    value: 'EG',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'eg'
  },
  jp: {
    code: 'jp',
    name: COUNTRIES_LABELS.jp,
    value: 'JP',
    languageCode: 'ja-JP',
    isEnabled: true,
    topDomain: 'co.jp'
  },
  se: {
    code: 'se',
    name: COUNTRIES_LABELS.se,
    value: 'SE',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'se'
  },
  pl: {
    code: 'pl',
    name: COUNTRIES_LABELS.pl,
    value: 'PL',
    languageCode: 'pl-PL',
    isEnabled: false,
    topDomain: 'pl'
  },
  au: {
    code: 'au',
    name: COUNTRIES_LABELS.au,
    value: 'AU',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'com.au'
  },
  sg: {
    code: 'sg',
    name: COUNTRIES_LABELS.sg,
    value: 'SG',
    languageCode: 'en-US',
    isEnabled: false,
    topDomain: 'sg'
  },
  cn: {
    code: 'cn',
    name: COUNTRIES_LABELS.cn,
    value: 'CN',
    languageCode: 'zh-CN',
    isEnabled: false,
    topDomain: 'cn'
  }
}

export const MARKETPLACES_REGIONS = {
  na: 'North America',
  eu: 'Europe',
  fe: 'Far East'
}

export const MARKETPLACES_OPP_FINDER = {
  us: {
    code: 'us',
    name: 'United States',
    value: 'US',
    languageCode: 'en-US',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.US
  },
  ca: {
    code: 'ca',
    name: 'Canada',
    value: 'CA',
    languageCode: 'en-CA',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.CA
  },
  mx: {
    code: 'mx',
    name: 'Mexico',
    value: 'MX',
    languageCode: 'es-MX',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.MX
  },
  de: {
    code: 'de',
    name: 'Germany',
    value: 'DE',
    languageCode: 'de-DE',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.DE
  },
  es: {
    code: 'es',
    name: 'Spain',
    value: 'ES',
    languageCode: 'es-ES',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.ES
  },
  fr: {
    code: 'fr',
    name: 'France',
    value: 'FR',
    languageCode: 'fr-FR',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.FR
  },
  it: {
    code: 'it',
    name: 'Italy',
    value: 'IT',
    languageCode: 'it-IT',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.IT
  },
  uk: {
    code: 'uk',
    name: 'United Kingdom',
    value: 'UK',
    languageCode: 'en-GB',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.UK
  },
  jp: {
    code: 'jp',
    name: 'Japan',
    value: 'JP',
    languageCode: 'ja-JP',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.JP
  },
  in: {
    code: 'in',
    name: 'India',
    value: 'IN',
    languageCode: 'hi-IN',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.IN
  }
}

export const MARKETPLACES_CATEGORY_TREND = {
  us: {
    code: 'us',
    name: 'United States',
    value: 'US',
    languageCode: 'en-US',
    isEnabled: true,
    dataId: SEARCH_DATA_ATTRIBUTE_IDS.Marketplaces.US
  }
}

export const MARKETPLACES_IN_TRANSITION = {
  us: { code: 'us', name: 'United States', value: 'US', isEnabled: true },
  ca: { code: 'ca', name: 'Canada', value: 'CA', isEnabled: true },
  de: { code: 'de', name: 'Germany', value: 'DE', isEnabled: true },
  es: { code: 'es', name: 'Spain', value: 'ES', isEnabled: true },
  fr: { code: 'fr', name: 'France', value: 'FR', isEnabled: true },
  it: { code: 'it', name: 'Italy', value: 'IT', isEnabled: true },
  mx: { code: 'mx', name: 'Mexico', value: 'MX', isEnabled: true },
  uk: { code: 'uk', name: 'United Kingdom', value: 'UK', isEnabled: true },
  jp: { code: 'jp', name: 'Japan', value: 'JP', isEnabled: true },
  in: { code: 'in', name: 'India', value: 'IN', isEnabled: true }
}
